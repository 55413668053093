import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import escapeHtml from 'escape-html'
import isNil from 'lodash/isNil'
import { ChatLayoutVersion } from '@pactum/core-backend-types'
import { Box, styled } from '@mui/material'

import { ChatBox } from '@containers/ChatBox/ChatBox'
import { ChatInput } from '@containers/ChatInput'
import { ChatDataTestId, ChatStep, isBasicUserInputStep, StepSubmitValue } from '@typedef/chatSteps'
import ChatWrapperSelector from '@containers/ChatWrapperSelector'
import ChatErrorPage from '@pages/ChatErrorPage'
import { DebugToolbarEntryPoint } from '@containers/DebugToolbar'
import CookiePolicy from '@components/CookiePolicy/CookiePolicy'
import { setPosthogActiveProject } from '@utils/posthog'
import { BRANDING_QUERY_PARAM_NAME, ChatUrlParams } from '@constants/routes'
import { useAppSelector } from '@store/index'
import { isCoreBackendApiError, useGetChatParamsQuery, useGetNextStepMutation } from '@store/api'
import { useAuth } from '@store/authHooks'
import { FullPageLoader } from '@components/FullPageLoader/FullPageLoader'
import { DebugToolbarProvider } from '@context/DebugToolbar'
import { useQueryParams } from '@hooks/useQueryParams'
import { AccessKeyForm } from 'containers/AccessKeyForm'
import useTranslations from '../localisation/useTranslations'
import { StandalonePageContainer } from '@containers/StandalonePageContainer'
import { ChatEvent, SimulatorInput } from '@utils/simulator/inputs'
import { ChatContextProvider } from './ChatContext'
import { MaintenanceErrorPage } from '@pages/MaintenanceErrorPage'

const DEFAULT_CHAT_LAYOUT = ChatLayoutVersion.WIDE_WITH_LIVE_SIDEBAR

const Chat = (): JSX.Element => {
  const [lastUserMessage, setLastUserMessage] = useState('')
  const [inputError, setInputError] = useState('')
  const [debugDelay, setDebugDelay] = useState<number | null>(null)

  const localise = useTranslations()

  const { token, delay, stateId, stepId, stepCount } = useParams<ChatUrlParams>()
  const brandingUuid = useQueryParams().get(BRANDING_QUERY_PARAM_NAME) ?? undefined
  const delayFromQueryParams = !isNil(delay) ? +delay : undefined

  const accessKeyStorageKey = `access-key-${token}`

  const [enteredAccessKey, setEnteredAccessKey] = useState<string | null>(() => {
    return sessionStorage.getItem(accessKeyStorageKey)
  })
  const [formWasSubmitted, setFormWasSubmitted] = useState(false)
  const [accessKeyIsInvalid, setAccessKeyIsInvalid] = useState<boolean>(enteredAccessKey === null)
  const { isAuthenticating, linkAuthInfoError, linkAuthInfo } = useAuth()
  const needsToEnterAccessKey =
    linkAuthInfo?.isLinkProtected && (!enteredAccessKey || accessKeyIsInvalid)
  const { data: chatParams, error: chatParamsError } = useGetChatParamsQuery(
    { token, stateId, stepId, stepCount, brandingUuid },
    { skip: isAuthenticating },
  )
  const [
    getNextStep,
    {
      isLoading: nextStepIsLoading,
      error: nextStepError,
      originalArgs: previousGetNextStepArgs,
      isSuccess: lastNextStepHasSucceeded,
    },
  ] = useGetNextStepMutation()
  const {
    authInfo: { readOnly: isReadOnly },
    steps,
  } = useAppSelector((state) => state.chat)
  const lastChatObject: ChatStep | undefined = steps[steps.length - 1]
  const chatDelay: number =
    debugDelay ??
    delayFromQueryParams ??
    lastChatObject?.delayInMilliseconds ??
    chatParams?.chatDelay ??
    1000
  const chatIsInitializing = isAuthenticating || !chatParams || needsToEnterAccessKey

  const submitAccessKey = (accessKey: string) => {
    setEnteredAccessKey(accessKey)
    getNextStep({
      ...(previousGetNextStepArgs ?? { token, stateId: chatParams?.stateId, stepInput: [] }),
      accessKey: accessKey,
    })
  }

  useEffect(() => {
    if (enteredAccessKey !== null && accessKeyIsInvalid !== undefined) {
      if (!accessKeyIsInvalid) {
        sessionStorage.setItem(accessKeyStorageKey, enteredAccessKey)
      }
    }
  }, [enteredAccessKey, accessKeyStorageKey, accessKeyIsInvalid])

  useEffect(() => {
    if (chatParams) {
      const pageTitle = chatParams.shortName ?? chatParams.name ?? localise('supplierPortal')

      if (document.title !== pageTitle) {
        document.title = pageTitle
      }

      if (chatParams.locale) {
        document.documentElement.lang = chatParams.locale
      }
    }
  }, [chatParams, localise])

  useEffect(() => {
    if (!chatIsInitializing) {
      setPosthogActiveProject(chatParams.projectTag)
    }
  }, [chatIsInitializing, chatParams, token])

  useEffect(() => {
    if (!chatIsInitializing && !nextStepIsLoading && !nextStepError) {
      let delay = chatDelay
      if (lastChatObject === undefined) {
        getNextStep({
          token,
          stateId: chatParams.stateId,
          delay,
          accessKey: enteredAccessKey,
          stepInput: [],
        })
        return
      }

      if (
        'trigger' in lastChatObject &&
        lastChatObject.trigger !== null &&
        lastChatObject.trigger !== ''
      ) {
        if ('message' in lastChatObject && lastChatObject.message === '-') {
          delay = 0
        }
        getNextStep({
          token,
          stateId: chatParams.stateId,
          stepId: lastChatObject.trigger,
          delay,
          accessKey: enteredAccessKey,
          stepInput: [],
        })
      }
    }
  }, [
    chatIsInitializing,
    nextStepIsLoading,
    nextStepError,
    getNextStep,
    chatParams,
    lastChatObject,
    token,
    chatDelay,
    enteredAccessKey,
  ])

  useEffect(() => {
    if (inputError) {
      setTimeout(() => setInputError(''), 3000)
    }
  }, [inputError])

  useEffect(() => {
    // clear temporary client-side submit message
    const isUserMessage = lastChatObject && 'user' in lastChatObject && lastChatObject.user
    const isMessagePresent =
      lastChatObject && 'message' in lastChatObject && !!lastChatObject.message
    if (isUserMessage && isMessagePresent) {
      setLastUserMessage('')
    }
  }, [lastChatObject])

  useEffect(() => {
    // clear client-side submit message if there is an error
    if (nextStepError) {
      setLastUserMessage('')
      if (
        isCoreBackendApiError(nextStepError) &&
        nextStepError.data.meta.internalCode === 'ACCESS_KEY_INVALID'
      ) {
        setFormWasSubmitted(true)
        setAccessKeyIsInvalid(true)
      }
    } else if (lastNextStepHasSucceeded) {
      setAccessKeyIsInvalid(false)
    }
  }, [nextStepError, lastNextStepHasSucceeded])

  const apiError = linkAuthInfoError ?? chatParamsError
  if (apiError !== undefined) {
    return <ChatErrorPage queryError={apiError} />
  }
  if (chatParams?.isMaintenanceMode) {
    return <MaintenanceErrorPage />
  }

  if (needsToEnterAccessKey && chatParams) {
    return (
      <StandalonePageContainer
        logo={chatParams.headerImage ?? ''}
        clientName={chatParams.name ?? ''}
      >
        <AccessKeyForm
          onSubmit={submitAccessKey}
          loading={nextStepIsLoading}
          defaultValue={enteredAccessKey}
          errorMessage={
            accessKeyIsInvalid && formWasSubmitted ? localise('err_access_key_invalid') : undefined
          }
        />
      </StandalonePageContainer>
    )
  } else if (chatIsInitializing) {
    return <FullPageLoader />
  }

  const escapeAndSetLastUserMessage = (rawMessage: string) => {
    setLastUserMessage(escapeHtml(rawMessage))
  }

  const retryPreviousStep = () => {
    if (!chatIsInitializing && previousGetNextStepArgs) {
      getNextStep(previousGetNextStepArgs)
    }
  }

  const requestNextStep = (
    trigger?: string,
    value?: StepSubmitValue,
    stepInput?: SimulatorInput,
  ) => {
    if (!chatIsInitializing) {
      getNextStep({
        token,
        stepId: trigger,
        stateId: chatParams.stateId,
        value,
        delay: 0,
        accessKey: enteredAccessKey,
        stepInput: stepInput ? [stepInput] : [],
      })

      if (stepInput) {
        document.dispatchEvent(
          new CustomEvent(ChatEvent.USER_MESSAGE_POSTED, {
            detail: stepInput,
          }),
        )
      }
    }
  }

  const simpleInputShown =
    !isReadOnly && !nextStepIsLoading && isBasicUserInputStep(lastChatObject) && !nextStepError

  const layoutVersion =
    chatParams.layoutVersion &&
    [ChatLayoutVersion.WIDE, ChatLayoutVersion.WIDE_WITH_LIVE_SIDEBAR].includes(
      chatParams.layoutVersion,
    )
      ? chatParams.layoutVersion
      : DEFAULT_CHAT_LAYOUT

  return (
    <DebugToolbarProvider>
      <ChatContextProvider
        stateId={chatParams?.stateId}
        locale={chatParams?.locale}
        linkToken={token}
      >
        <ChatWrapperSelector layoutVersion={layoutVersion}>
          <ChatContainer data-testid={ChatDataTestId.CHAT}>
            <ChatBox
              lastUserMessage={lastUserMessage}
              retryPreviousStep={retryPreviousStep}
              requestNextStep={requestNextStep}
              chatStepError={nextStepError}
              setLastUserMessage={escapeAndSetLastUserMessage}
              isLoading={nextStepIsLoading}
              simpleInputShown={simpleInputShown}
            />
            {simpleInputShown ? (
              <ChatInput
                lastChatObject={lastChatObject}
                requestNextStep={requestNextStep}
                setLastUserMessage={escapeAndSetLastUserMessage}
                setInputError={setInputError}
                inputError={inputError}
              />
            ) : null}
            <CookiePolicy />
          </ChatContainer>
        </ChatWrapperSelector>
      </ChatContextProvider>
      <DebugToolbarEntryPoint chatDelay={chatDelay} setDebugDelay={setDebugDelay} />
    </DebugToolbarProvider>
  )
}

const ChatContainer = styled(Box)({
  position: 'relative',
  height: '100%',
  maxWidth: '100%',
  width: '100%',
  margin: `0 auto`,
  boxShadow: 'none',
  borderRadius: 0,
  overflow: 'hidden',

  '@media print': {
    height: 'auto',
  },
})

export default Chat
