import React, { useState } from 'react'
import { Button, CircularProgress, Stack, TextField } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import { ChatMessage } from '@components/ChatMessage/ChatMessage'
import { ChatDataTestId } from '@typedef/testIDs'
import {
  TextToChoiceStep as TextToChoiceStepType,
  TextToChoiceSubmitValue,
} from '@typedef/chatSteps/TextToChoiceStep'
import { SimulatorOptions } from '@utils/simulator/inputs'
import { ChatOptions } from '@components/ChatOptions/ChatOptions'
import { StepOption } from '@typedef/chatSteps/OptionsStep'
import useTranslations from '../../../localisation/useTranslations'
import { StepType } from '@typedef/chatSteps'

export interface Props {
  step: TextToChoiceStepType
  sendValue: (value: TextToChoiceSubmitValue, stepInput?: SimulatorOptions, label?: string) => void
}

export const TextToChoiceStep = ({ step, sendValue }: Props) => {
  const options = step.options

  const onSubmit = (option?: StepOption, userMessage?: string) => {
    const submitValue = option ? { selectedChoiceId: option.value } : { userMessage }

    sendValue(
      submitValue,
      options.length > 1
        ? {
            type: StepType.OPTIONS_STEP,
            value: {
              choiceIndex: 0, // placeholder value for now
            },
          }
        : undefined,
    )
  }

  return (
    <ChatMessage
      stepId={step.id}
      user={false}
      dataTestId={ChatDataTestId.TEXT_TO_CHOICE_STEP}
      message={
        // Condition will be based on amount of retries and whether text to choice is enabled
        true ? <TextInput onSubmit={onSubmit} /> : <Options options={options} onSubmit={onSubmit} />
      }
      shouldShowAvatar={false}
      shouldAnimate={true}
    />
  )
}

interface OptionsProps {
  options: StepOption[]
  onSubmit: (option: StepOption) => void
}

const Options = ({ options, onSubmit }: OptionsProps) => {
  return <ChatOptions choices={options} onChipClick={onSubmit} />
}

interface TextInputProps {
  onSubmit: (option?: StepOption, userMessage?: string) => void
}

const TextInput = ({ onSubmit }: TextInputProps) => {
  const [inputValue, setInputValue] = useState('')
  const localise = useTranslations()
  const minChars = 20 // hardcoded for now
  const progress = Math.round((inputValue.length / minChars) * 100)

  return (
    <Stack direction='row' alignItems='center' mr={-3} minWidth={400}>
      <TextField
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        variant='outlined'
        fullWidth
        type='text'
        inputProps={{
          inputMode: 'text',
          'data-testid': ChatDataTestId.TEXT_TO_CHOICE_TEXT_INPUT,
        }}
        InputProps={{
          disableUnderline: true,
          endAdornment:
            progress >= 100 ? null : (
              <CircularProgress
                variant='determinate'
                size={40}
                value={progress}
                sx={{
                  ml: 2,
                  my: -2,
                  transition: 'opacity 300ms ease',
                  ...(progress >= 100 ? { opacity: 0 } : {}),
                }}
              />
            ),
        }}
        placeholder={localise('typeAText')}
        autoFocus={true}
        multiline={true}
      />
      <Button
        aria-label='send'
        data-testid={ChatDataTestId.CHAT_SUBMIT_BUTTON}
        disabled={progress < 100}
        color='primary'
        onClick={() => onSubmit(undefined, inputValue)}
        sx={{
          height: 56,
          transition: 'color 300ms ease',
        }}
      >
        <SendIcon />
      </Button>
    </Stack>
  )
}
