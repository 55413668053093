import React from 'react'
import { Box, Zoom, Stack } from '@mui/material'

import { ChatDataTestId } from '@typedef/testIDs'
import ChipButton from '@components/ChipButton/ChipButton'

import { useIsReadOnlyChatSession } from '@store/selectors'
import { StepOption } from '@typedef/chatSteps/OptionsStep'

interface Props {
  choices: StepOption[]
  onChipClick: (value: StepOption, index: number) => void
  children?: React.ReactElement
  align?: 'left' | 'center'
  size?: 'large' | 'medium'
}

export const ChatOptions = ({
  choices,
  onChipClick,
  children,
  align = 'center',
  size = 'large',
}: Props): JSX.Element => {
  const isReadOnly = useIsReadOnlyChatSession()

  return (
    <Box pb={1} sx={{ width: '100%' }} data-testid={ChatDataTestId.OPTION_BUTTONS_CONTAINER}>
      <Zoom in={true} style={{ transformOrigin: 'center' }}>
        <Stack
          direction='row'
          justifyContent={align === 'left' ? 'flex-start' : 'center'}
          flexWrap='wrap'
          gap={1}
          pt={1}
          pb={0}
          px={align === 'left' ? 0 : 2.5}
        >
          {choices.map((choice, index) => (
            <ChipButton
              key={`${choice.label}-${index}`}
              data-testid={ChatDataTestId.OPTION_BUTTON}
              color='primary'
              variant={choice.uiProperties?.variant ?? 'outlined'}
              label={choice.label}
              labelSize={size}
              disabled={isReadOnly || choice.disabled}
              onClick={() => onChipClick(choice, index)}
              greyedOutNoBorders={choice.uiProperties?.grayedOutNoBordersButton}
            />
          ))}
          {children}
        </Stack>
      </Zoom>
    </Box>
  )
}
